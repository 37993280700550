@font-face {
  font-family: 'barkentina';
  src: url('./fonts/barkentina.eot');
  src: url('./fonts/barkentina.eot?#iefix') format('embedded-opentype'),
    url('./fonts/barkentina.woff') format('woff'),
    url('./fonts/barkentina.ttf') format('truetype');
}

@font-face {
  font-family: 'magpie-paper-works';
  src: url('./fonts/magpie-paper-works.eot');
  src: url('./fonts/magpie-paper-works.eot?#iefix') format('embedded-opentype'),
    url('./fonts/magpie-paper-works.woff') format('woff'),
    url('./fonts/magpie-paper-works.ttf') format('truetype');
}

@font-face {
  font-family: 'icomoon-social';
  src: url('./fonts/icomoon-social.eot?7nuigq');
  src: url('./fonts/icomoon-social.eot?7nuigq#iefix')
      format('embedded-opentype'),
    url('./fonts/icomoon-social.ttf?7nuigq') format('truetype'),
    url('./fonts/icomoon-social.woff?7nuigq') format('woff'),
    url('./fonts/icomoon-social.svg?7nuigq#icomoon-social') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='soc-'],
[class*=' soc-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.soc-soundcloud:before {
  content: '\e900';
}
.soc-brand:before {
  content: '\e900';
}
.soc-social:before {
  content: '\e900';
}
.soc-twitter:before {
  content: '\e901';
}
.soc-brand1:before {
  content: '\e901';
}
.soc-tweet:before {
  content: '\e901';
}
.soc-social1:before {
  content: '\e901';
}
.soc-facebook:before {
  content: '\e902';
}
.soc-brand2:before {
  content: '\e902';
}
.soc-social2:before {
  content: '\e902';
}

.soc-insta:before {
  content: '';
  display: inline-block;
  background-image: url(/img/insta.png);
  width: 32px;
  height: 32px;
  transform: translateY(4px);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#loader-container {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  opacity: 1;
  min-height: 100vh;
  background-color: #e2e1e0;
  width: 100%;
  transition: all 0.95s ease;
  pointer-events: none;
}

#loader {
  width: 100%;
  height: 96vh;
  text-align: center;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease;
  pointer-events: none;
}

#loader-container.done-loading {
  opacity: 0;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cta {
  position: fixed;
  width: auto;
  height: auto;
  z-index: 100000;
  background-color: rgba(0 0 0/ 0.8);
  left: 0;
  top: 0;
  padding: 1.75rem;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  overscroll-behavior: contain;
}

.cta h2 {
  margin: 1rem 0;
}

.cta h2, .cta p {
  font-size: 90%;
}

.cta .qr-codes {
  display: flex;
  flex-flow: column nowrap;
  color: white;
}

.cta .qr-codes > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.cta .qr-codes img {
  border: 4px solid white;
  width: 160px;
  height: 160px;
}

.cta .qr-codes img:first-child {
  margin: 0 0 32px;
}

.qr-codes-dismiss {
  margin: 32px 0 128px 0;
  display: flex;
}

.cta.hide-cta {
  display: none;
}

@media screen and (min-width: 768px) {
  .cta {
    top: 18%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 2.25rem;
    width: 90vw;
    height: auto;
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .cta h2, .cta p {
    font-size: 90%;
  }
  .cta p {
    min-height: 80px;
  }
  .cta .qr-codes {
    flex-flow: row nowrap;
  }
  .cta .qr-codes img:first-child {
    margin: 0 32px 0 0;
  }
}

@media screen and (min-width: 1080px) {
  .cta {
    top: 10%;
    padding: 3.75rem;
    width: 90vw;
    max-width: 1080px;
  }
  .cta h2 {
    align-self: flex-start;
  }
  .cta h2, .cta p {
    font-size: initial;
  }
  .qr-codes-dismiss {
    margin: 32px 0 0 0;
    display: flex;
  }
  /* .cta .qr-codes {
    flex-flow: row nowrap;
  }
  .cta .qr-codes img:first-child {
    margin: 0 32px 0 0;
  } */
}