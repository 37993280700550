@font-face {
  font-family: 'barkentina';
  src: url(/static/media/barkentina.1b01caa1.eot);
  src: url(/static/media/barkentina.1b01caa1.eot?#iefix) format('embedded-opentype'),
    url(/static/media/barkentina.b66c2b02.woff) format('woff'),
    url(/static/media/barkentina.5d226baa.ttf) format('truetype');
}

@font-face {
  font-family: 'magpie-paper-works';
  src: url(/static/media/magpie-paper-works.e36577b0.eot);
  src: url(/static/media/magpie-paper-works.e36577b0.eot?#iefix) format('embedded-opentype'),
    url(/static/media/magpie-paper-works.7dcb89a2.woff) format('woff'),
    url(/static/media/magpie-paper-works.0dca309b.ttf) format('truetype');
}

@font-face {
  font-family: 'icomoon-social';
  src: url(/static/media/icomoon-social.31b890df.eot);
  src: url(/static/media/icomoon-social.31b890df.eot#iefix)
      format('embedded-opentype'),
    url(/static/media/icomoon-social.4bd81e39.ttf) format('truetype'),
    url(/static/media/icomoon-social.fe7dea75.woff) format('woff'),
    url(/static/media/icomoon-social.02712b78.svg#icomoon-social) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='soc-'],
[class*=' soc-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.soc-soundcloud:before {
  content: '\E900';
}
.soc-brand:before {
  content: '\E900';
}
.soc-social:before {
  content: '\E900';
}
.soc-twitter:before {
  content: '\E901';
}
.soc-brand1:before {
  content: '\E901';
}
.soc-tweet:before {
  content: '\E901';
}
.soc-social1:before {
  content: '\E901';
}
.soc-facebook:before {
  content: '\E902';
}
.soc-brand2:before {
  content: '\E902';
}
.soc-social2:before {
  content: '\E902';
}

.soc-insta:before {
  content: '';
  display: inline-block;
  background-image: url(/img/insta.png);
  width: 32px;
  height: 32px;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#loader-container {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  opacity: 1;
  min-height: 100vh;
  background-color: #e2e1e0;
  width: 100%;
  -webkit-transition: all 0.95s ease;
  transition: all 0.95s ease;
  pointer-events: none;
}

#loader {
  width: 100%;
  height: 96vh;
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: none;
}

#loader-container.done-loading {
  opacity: 0;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cta {
  position: fixed;
  width: auto;
  height: auto;
  z-index: 100000;
  background-color: rgba(0 0 0/ 0.8);
  left: 0;
  top: 0;
  padding: 1.75rem;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

.cta h2 {
  margin: 1rem 0;
}

.cta h2, .cta p {
  font-size: 90%;
}

.cta .qr-codes {
  display: flex;
  flex-flow: column nowrap;
  color: white;
}

.cta .qr-codes > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.cta .qr-codes img {
  border: 4px solid white;
  width: 160px;
  height: 160px;
}

.cta .qr-codes img:first-child {
  margin: 0 0 32px;
}

.qr-codes-dismiss {
  margin: 32px 0 128px 0;
  display: flex;
}

.cta.hide-cta {
  display: none;
}

@media screen and (min-width: 768px) {
  .cta {
    top: 18%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    padding: 2.25rem;
    width: 90vw;
    height: auto;
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .cta h2, .cta p {
    font-size: 90%;
  }
  .cta p {
    min-height: 80px;
  }
  .cta .qr-codes {
    flex-flow: row nowrap;
  }
  .cta .qr-codes img:first-child {
    margin: 0 32px 0 0;
  }
}

@media screen and (min-width: 1080px) {
  .cta {
    top: 10%;
    padding: 3.75rem;
    width: 90vw;
    max-width: 1080px;
  }
  .cta h2 {
    align-self: flex-start;
  }
  .cta h2, .cta p {
    font-size: medium;
    font-size: initial;
  }
  .qr-codes-dismiss {
    margin: 32px 0 0 0;
    display: flex;
  }
  /* .cta .qr-codes {
    flex-flow: row nowrap;
  }
  .cta .qr-codes img:first-child {
    margin: 0 32px 0 0;
  } */
}
/* ----------------------------------------
    ROOT STUFF
----------------------------------------- */
:root {
  --brand: #f8b340;
  --brand-lite: #deb887;
  --white: rgb(252, 250, 249);
  --dark-gray: #2d2b30;
  --green: #91b970;
  --blue: #56bff9;
  --dark-blue: #1e1e42;
  --orange: #ff8f5d;
  --gold: #f6a82c;
  --dark-orange: #3a1d0b;
  --turkish: #41bbbb;
  --ghost: rgba(255, 255, 255, 0.4);
  --sml-header-height: 150px;
}

html {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  background: #e2e1e0;
}

body {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.green-bg {
  background-color: #91b970;
  background-color: var(--green);
}

.blue-bg {
  background-color: #56bff9;
  background-color: var(--blue);
}

.dark-blue-bg {
  background-color: #1e1e42;
  background-color: var(--dark-blue);
}

.orange-bg {
  background-color: #ff8f5d;
  background-color: var(--orange);
}

.dark-orange-bg {
  background-color: #3a1d0b;
  background-color: var(--dark-orange);
}

.white-bg {
  background-color: rgb(252, 250, 249);
  background-color: var(--white);
}

.brand-bg {
  background-color: #f8b340;
  background-color: var(--brand);
}

.turkish-bg {
  background-color: #41bbbb;
  background-color: var(--turkish);
}

.ghost-bg {
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--ghost);
}

.gold-bg {
  background-color: #f6a82c;
  background-color: var(--gold);
}

.green {
  color: #91b970;
  color: var(--green);
}

.blue {
  color: #56bff9;
  color: var(--blue);
}

.dark-blue {
  color: #1e1e42;
  color: var(--dark-blue);
}

.orange {
  color: #ff8f5d;
  color: var(--orange);
}

.dark-orange {
  color: #3a1d0b;
  color: var(--dark-orange);
}

.white {
  color: rgb(252, 250, 249);
  color: var(--white);
}

.white-text {
  color: black !important;
}

.brand-color {
  color: #f8b340;
  color: var(--brand);
}

.turkish {
  color: #41bbbb;
  color: var(--turkish);
}

.gold {
  color: #f6a82c;
  color: var(--gold);
}

#root {
  position: relative;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 56px;
  height: 56px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f8b340;
  border: 4px solid var(--brand);
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  pointer-events: none;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  pointer-events: none;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.hidden {
  visibility: hidden !important;
}

.hidden-lg {
  display: block;
}

.mt-40 {
  margin-top: 40px !important;
}
.mt-64 {
  margin-top: 64px !important;
}

.upper {
  text-transform: uppercase;
  height: auto !important;
  line-height: normal !important;
  line-height: initial !important;
  padding: 12px 0;
  margin-bottom: 64px !important;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.v-align-parent {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  height: inherit;
}

.v-align-child {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.button {
  display: inline-block;
  width: 150px;
  height: 50px;
  line-height: 50px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  color: white;
  text-decoration: none;
}

.responsive {
  width: 100%;
  max-width: 720px;
  height: auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  margin: 26px 0;
}

/* ----------------------------------------
    HEADER STUFF
----------------------------------------- */

#header {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #2d2b30;
  background-color: var(--dark-gray);
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  z-index: 100;
}

#header * {
  margin: 0;
  padding: 0;
}

/*@media (min-width: 700px) and (orientation: landscape) { ... }*/

#header::after {
  display: none;
}

#header::before {
  display: block;
  content: '';
  position: absolute;
  background-color: #2d2b30;
  background-color: var(--dark-gray);
  z-index: 901;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  opacity: 0.6;
  pointer-events: none;
}

#header::after {
  display: block;
  content: '';
  position: absolute;
  background-color: #2d2b30;
  background-color: var(--dark-gray);
  z-index: 900;
  width: 100%;
  height: 150px;
  height: var(--sml-header-height);
  bottom: 0;
  left: 0;
  opacity: 0.6;
  pointer-events: none;
}

#header.open {
  -webkit-transform: translate3d(0, -100%, 0) translate3d(0, 150px, 0);
  transform: translate3d(0, -100%, 0) translate3d(0, 150px, 0);
}

.logo-path {
  fill: #f8b340;
  fill: var(--brand);
}

.brand {
  display: none;
}

#reveal-mobile {
  display: block;
  position: absolute;
  bottom: 6px;
  left: calc(50% - 24px);
  /* transform: translateX(-100%);
  -webkit-transform: translateX(-100%); */
  width: 48px;
  height: 48px;
  border-style: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  z-index: 921;
}

#reveal {
  display: none;
  position: absolute;
  bottom: 32px;
  left: calc(50% - 32px);
  /* transform: translateX(-100%);
  -webkit-transform: translateX(-100%); */
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border-style: none;
  background-color: transparent;
  border: 2px solid #f8b340;
  border: 2px solid var(--brand);
  color: white;
  cursor: pointer;
  z-index: 920;
  pointer-events: none;
}

#reveal-mobile:active,
#reveal-mobile:focus,
#reveal:active,
#reveal:focus {
  outline: none;
}

#reveal div {
  position: relative;
  width: 100%;
  height: 100%;
}

#reveal .reveal-chev {
  position: absolute;
  left: 9px;
  top: 11px;
  z-index: 110;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

#reveal .reveal-chev:last-child {
  z-index: 120;
}

#reveal .reveal-chev.dismiss:first-child {
  top: 5px;
}

#reveal .reveal-chev.dismiss:last-child {
  -webkit-transform: rotate(900deg);
  transform: rotate(900deg);
  top: 15px;
}

.mobile-view-heading {
  font-family: barkentina;
  font-size: 36px;
  text-align: center;
  line-height: 36px;
  /* margin: -96px 0 196px 0 !important; */
}

/* ----------------------------------------
    SLIDER STUFF
----------------------------------------- */

.slider-container {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 150px); */
  height: 100vh;
  background-color: #2d2b30;
  background-color: var(--dark-gray);
  opacity: 1;
  -webkit-transition: all 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.slider-container.hide {
  opacity: 0;
}

.slider-container ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: inherit;
  width: 100%;
  overflow: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-perspective-origin: center;
          perspective-origin: center;
}

.slider-container ul li {
  display: block;
  visibility: hidden;
  /*
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
*/
  width: 100%;
  height: inherit;
  -webkit-transform-origin: 50% 120%;
  transform-origin: 50% 120%;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-container ul li.active {
  width: 100%;
  height: inherit;
  visibility: visible;
}

.slider-container ul li img {
  width: 100%;
  height: auto;
}

/* .indicators-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: 0;
  z-index: 930;
  height: var(--sml-header-height);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  left: calc(50% - 45px);
} */

.indicators-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  display: flex;
  /* bottom: 0; */
  left: 0;
  justify-content: center;
  z-index: 930;
  height: 150px;
  height: 60px;
  width: 100vw;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  left: 0;
  /* background-color: var(--dark-gray); */
}

#header ul .indicator {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  float: left;
  margin: 0 6px;
  padding: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-clip: padding-box;
  background-color: transparent;
  border: 6px solid transparent;
  box-shadow: 0 0 0 2px #f8b340;
  box-shadow: 0 0 0 2px var(--brand);
  cursor: pointer;
}

#header ul .indicator.active {
  background-color: #f8b340;
  background-color: var(--brand);
}

/* ----------------------------------------
    MAIN CONTENT
----------------------------------------- */
.main-content {
  position: relative;
  min-height: 100vh;
  background: #e2e1e0;
  padding: 15px 0 0 0;
}

#about {
  text-align: center;
  color: #2d2b30;
  color: var(--dark-gray);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  padding: 0 12px;
}

.content-title {
  font-family: 'magpie-paper-works', serif;
  margin: 64px 0 2px;
}

.content-subtitle {
  font-weight: 400;
  margin: 0 0 48px 0;
}

.text-content {
  max-width: 720px;
  margin: 0 auto;
  line-height: 24px;
}

#about img {
  display: block;
  text-align: center;
  margin: 0 auto 12px;
  border-radius: 2px;
  border: 6px solid white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.3);
}

/* .product-list {
  display: flex;
  flex-flow: column-reverse nowrap;
  max-width: 720px;
  margin: 0 auto;
  justify-content: space-between;
} */

.product-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 480px;
  margin: 0 auto;
}

.product-list img {
  cursor: pointer !important;
}

.socials a {
  border-radius: 50%;
  width: 64px;
  height: 64px !important;
  font-size: 32px;
  margin: 64px 16px 64px 16px !important;
}

.nav-container {
  width: 100%;
  background: #2d2b30;
  background: var(--dark-gray);
}

.nav-footer-meta {
  text-align: center;
  margin-bottom: 32px;
  max-width: 720px;
  margin: 0 auto 0;
  padding: 12px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 160px;
  height: auto;
}

.nav-footer-meta a,
.nav-footer-meta a:hover,
.nav-footer-meta a:active,
.nav-footer-meta a:visited {
  text-decoration: none;
  font-family: 'magpie-paper-works', serif;
  color: white;
}

/*-------------------------------------------
  Breakpoints
--------------------------------------------*/
@media all and (min-width: 1200px) {
}

@media all and (min-width: 1020px) {
  html {
    overflow-y: hidden;
  }
  .main-content {
    padding: 150px 0 0 0;
  }
  #about {
    overflow-y: scroll !important;
    max-height: calc(100vh - 200px);
  }
  /* .product-list {
    flex-flow: row-reverse wrap;
  } */
  .nav-footer-meta {
    flex-flow: row nowrap;
    min-height: 50px;
  }
  #reveal {
    pointer-events: all;
    display: block;
  }
  #reveal-mobile {
    display: none;
  }
}

@media all and (orientation: landscape) and (max-width: 991px) {
  .hidden-lg {
    display: none;
  }
  #header::after {
    height: 60px;
  }
}
@media all and (orientation: portrait) and (max-width: 991px) {
  #header::after {
    height: 60px;
  }
}
@media all and (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
  #header {
    position: absolute;
  }

  .brand {
    display: block;
    position: absolute;
    bottom: 32px;
    left: 32px;
    z-index: 910;
  }
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 575px) {
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.animateInLeft,
.animateInRight,
.animateOutLeft,
.animateOutRight {
  -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  /* 
  -webkit-animation: slide-out-bottom 0.8s both;
  animation: slide-out-bottom 0.8s both; */
}

.animateOutRight {
  -webkit-animation-name: animateOutRight;
          animation-name: animateOutRight;
  /* animation-name: slide-out-top; */
}

.animateInLeft {
  -webkit-animation-name: animateInLeft;
          animation-name: animateInLeft;
  /* animation-name: slide-in-bottom; */
}

.animateInRight {
  -webkit-animation-name: animateInRight;
          animation-name: animateInRight;
  /* animation-name: slide-in-top; */
}

.animateOutLeft {
  -webkit-animation-name: animateOutLeft;
          animation-name: animateOutLeft;
  /* animation-name: slide-out-bottom; */
}

/* ----------------------------------------------
 * Generated by Animista on 2019-4-28 1:33:23
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation animateOutRight
 * ----------------------------------------
 */
@-webkit-keyframes animateOutRightOld {
  from {
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
}
@keyframes animateOutRightOld {
  from {
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-br-ccw
 * ----------------------------------------
 */
@-webkit-keyframes animateOutLeftOld {
  from {
  } /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
  to {
    -webkit-transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
@keyframes animateOutLeftOld {
  from {
  } /* Fixes Chrome issue 35.0.1916.114 (easing breaks) */
  to {
    -webkit-transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation animateInLeft
 * ----------------------------------------
 */
@-webkit-keyframes animateInLeftOld {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
  }
}
@keyframes animateInLeftOld {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    transform: rotate3d(0, 0, 1, -20deg) translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
  }
}

/**
 * ----------------------------------------
 * animation animateInRight
 * ----------------------------------------
 */
@-webkit-keyframes animateInRightOld {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
  }
}
@keyframes animateInRightOld {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    transform: rotate3d(0, 0, 1, 20deg) translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
    transform: rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
  }
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------


.slide-in-top {
  -webkit-animation: slide-in-top 0.8s both;
  animation: slide-in-top 0.8s both;
}

 */

@-webkit-keyframes animateInRight {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes animateInRight {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.8s both;
  animation: slide-in-bottom 0.8s both;
}

 */

@-webkit-keyframes animateInLeft {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes animateInLeft {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------

.slide-out-top {
  -webkit-animation: slide-out-top 0.8s both;
  animation: slide-out-top 0.8s both;
}

 */

@-webkit-keyframes animateOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes animateOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------

.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.8s both;
  animation: slide-out-bottom 0.8s both;
}

 */

@-webkit-keyframes animateOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes animateOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

